// Enter all your detials in this file

// Profile Image
import profile from "./assets/profile.jpg";
// Tech stack images
import html from "./assets/techstack/html.png";
import css from "./assets/techstack/css.png";
import sass from "./assets/techstack/sass.png";
import js from "./assets/techstack/js.png";
import react from "./assets/techstack/react.png";
import redux from "./assets/techstack/redux.png";
import bootstrap from "./assets/techstack/bootstrap.png";
import vscode from "./assets/techstack/vscode.png";
import github from "./assets/techstack/github.png";
import git from "./assets/techstack/git.png";
import npm from "./assets/techstack/npm.png";
import postman from "./assets/techstack/postman.png";
import java from "./assets/techstack/java.png";
import mongo from "./assets/techstack/mongo.png";
import mysql from "./assets/techstack/mysql.png";
import nodejs from "./assets/techstack/nodejs.png";
import spring from "./assets/techstack/springboot.png";
import csharp from "./assets/techstack/csharp.png";

// Porject Images
import bbd from "./assets/projects/bbd.png";
import dungeon from "./assets/projects/dungeon.png";
import trackero from "./assets/projects/trackero.png";

// Enter your Personal Details here
export const personalDetails = {
  name: "Balogh Barnabás",
  tagline: "I build things for web",
  img: profile,
  about: `My name is Balogh Barnabás. I am a professional and enthusiastic programmer in my daily life. I am a quick learner with a self-learning attitude. I love to explore new technologies and i love about problem-solving. I love almost all the stacks of web application development love to make the web more open to the world. My core skills are JavaScript on frontend and Java on the backend. I am available for any kind of job opportunity that suits my skills and interests.`,
};

// Enter your Social Media URLs here
export const socialMediaUrl = {
  linkdein: "https://www.linkedin.com/in/barnabas-balogh",
  github: "https://www.github.com/baloghb0110",
};

// Enter your Work Experience here
export const workDetails = [
  {
    Position: "Electrician",
    Company: `BBD Comtech Kft.`,
    Location: "Budapest",
    Type: "Full Time",
    Duration: "2020 - Present",
  },
];

// Enter your Education Details here
export const eduDetails = [
  {
    Position: "Full Stack developer",
    Company: "Coodecool",
    Location: "Budapest",
    Type: "Full Time",
    Duration: "Feb 2023 - Feb 2024",
  },
  {
    Position: "Frontend Development",
    Company: "Udemy, YouTube, Google",
    Location: "Online",
    Type: "Full Time",
    Duration: "Jan 2020 - Present",
  },
  {
    Position: "Informatics",
    Company: ` Miskolci SZC Andrássy Gyula Gépipari Technikum`,
    Location: "Miskolc",
    Type: "Full Time",
    Duration: "2015 - 2020",
  },
];

// Tech Stack and Tools
export const techStackDetails = {
  html: html,
  css: css,
  js: js,
  react: react,
  redux: redux,
  sass: sass,
  bootstrap: bootstrap,
  vscode: vscode,
  postman: postman,
  npm: npm,
  git: git,
  github: github,
  java: java,
  mongo: mongo,
  mysql: mysql,
  nodejs: nodejs,
  spring: spring,
  csharp: csharp,
};

// Enter your Project Details here
export const projectDetails = [
  {
    title: "BBD ADMIN DASHBOARD",
    image: bbd,
    description: `This is my own project, made by myself, AngularJS and PHP-based admin dashboard
    tailored for managing attendance. It offers functionalities to create and track attendance
    history efficiently.`,
    techstack: "HTML/CSS, AngularJS, PHP",
    previewLink: "",
    githubLink: "https://github.com/baloghb0110/bbdapp",
  },
  {
    title: "DUNGEON CRAWL",
    image: dungeon,
    description: `This is a team effort project. My role was to make an artificial monsters that could move
    and follow the character autonomously. It's a two-dimensional survival game where you
    fight monsters and it's written in Java.`,
    techstack: "Java",
    previewLink: "",
    githubLink: "https://github.com/baloghb0110/dungeon-crawl-java-jfx-baloghb0110",
  },
  {
    title: "TRACKERO",
    image: trackero,
    description: `It is a team project, used to track expenditure and income. My task was to create a login and registration system with JWT authentication and authorization system`,
    techstack: "ReactJS, React Router, React Query, Axios, Java Spring Boot, Spring Data JPA / Hibernate, Spring Security, JWT",
    previewLink: "",
    githubLink: "https://github.com/baloghb0110/el-proyecte-grande-sprint-1-java",
  },
];

// Enter your Contact Details here
export const contactDetails = {
  email: "bbarnabas0101@gmail.com",
};
